<template>
  <div>
    <!-- pic3A7C1DBA9D3A9829B3CC56F4634FDCF5.jpg -->
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <img class="img-large rounded" src="">
    <p>
      Being nosy is all in a day’s work for the latest LEGO Universe character.
    </p>
    <p>
      Now imagine that you are a news reporter snooping for scoops in LEGO Universe. Will you break stories from battle zones? Hype celebrity lifestyles? Explore exotic vacation destinations?
    </p>
    <p>
      Grab your press badge and get to the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: 'FC711DAA-2552-4224-BA9E-41D01DFDF591'} }">Creation Lab</router-link> to report your discoveries!
    </p>
  </div>
</template>
